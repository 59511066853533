import request from '@/utils/request'

// 电子书信息
export function bookInfo() {
  return request({
    url: 'Book/BookInfo',
    method: 'Get'
  })
}

// 目录
export function bookCataList() {
  return request({
    url: 'Book/BookCataList',
    method: 'get'
  })
}

// 目录正文
export function bookBody(data) {
  return request({
    url: 'Book/BookBody',
    params: data,
    method: 'Get'
  })
}
