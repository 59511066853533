<template>
  <div class="home " :class="menuWidth? 'tab1':'tab2'" :style="{height:type===3?homeHeight:'100%'}">
    <!-- 视频 -->
    <el-tabs
      v-if="type ===1"
      v-model="gid"
      tab-position="left"
      style="margin-bottom: 50px; padding: 20px 40px;height: calc(100% - 60px);"
      @tab-click="onHandleClick"
    >
      <el-tab-pane
        v-for="(item) in grouping"
        :key="item.id"
        :label="item.name"
        :name="item.id"
        style="height: 100%;"
      >
        <template #label>
          <div v-if="item.style!=null" v-html="item.style"></div>
          <div v-else>{{ item.name }}</div>
        </template>
        <span slot="label" class="tab-name">&nbsp;&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;</span>
        <el-scrollbar class="scrollbar" style="height: calc(100%)">
          <!-- 多情景语块 -->
          <div v-if="item.name==='多情景语块'" class="row video-list">
            <div v-if="scenariosType!=2" class="row" style="flex-wrap: wrap;">
              <!-- 上一级 -->
              <div v-if="scenariosType!=2" style="margin:10px;height: 100%;">
                <div v-if="scenariosType!=0" class="column video">
                  <div
                    class="video-img"
                    style="height: 150px;width: 230px;background: rgb(49 49 49);"
                    @click="onScenarios(0)"
                  >
                    <svg
                      t="1661839587040"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1432"
                      width="60"
                      height="60"
                    >
                      <path
                        d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                        fill="#17abe3"
                      />
                    </svg>
                  </div>
                  <div class="column content">
                    <span class="video-name">上一级</span>
                    <span class="video-tag">上一级</span>
                  </div>
                </div>
              </div>
              <!-- 子分组 -->
              <div
                v-for="(sl) in scenariosList"
                :key="sl.name"
                style="margin:10px;height: 100%;"
                @click="onScenarios(sl.type,sl.name)"
              >
                <div class="column video">
                  <div
                    class="video-img"
                    style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  >
                    <svg
                      t="1661839587040"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1432"
                      width="60"
                      height="60"
                    >
                      <path
                        d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                        fill="#17abe3"
                      />
                    </svg>
                  </div>
                  <div class="column content">
                    <span class="video-name">{{ sl.name }}</span>
                    <span class="video-tag">{{ sl.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="row video-list">
              <!-- 上一级 -->
              <div v-if="scenariosType!=0" class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  @click="onScenarios(0)"
                >
                  <svg
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                </div>
                <div class="column content">
                  <span class="video-name">上一级</span>
                  <span class="video-tag">上一级</span>
                </div>
              </div>
              <div
                v-for="(video) in scenariosList"
                :key="video.vid"
                style="margin:10px;height: 100%;"
                @click="onVideoClick(video.id, item.id)"
              >
                <div class="column video">
                  <el-image class="video-img" fit="cover" :src="video.cover" lazy />
                  <div class="column content">
                    <span class="video-name">{{ video.name }}</span>
                    <span class="video-tag">{{ video.tag }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 正常视频分组 -->
          <div v-else class="row video-list">
            <!-- 上一级 -->
            <div v-if="pgid.length>1" style="margin:10px;height: 100%;" @click="onLastGrouping()">
              <div class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                >
                  <svg
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                </div>
                <div class="column content">
                  <span class="video-name">上一级</span>
                  <span class="video-tag">上一级</span>
                </div>
              </div>
            </div>
            <!-- 子分组 -->
            <div v-for="(cg) in childGroup" :key="cg.name" style="margin:10px;height: 100%;">
              <div class="column video">
                <div
                  class="video-img"
                  style="height: 150px;width: 230px;background: rgb(49 49 49);"
                  @click="onGrouping(cg.id)"
                >
                  <svg
                    v-if="!cg.ico"
                    t="1661839587040"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1432"
                    width="60"
                    height="60"
                  >
                    <path
                      d="M81.16 412.073333L0 709.653333V138.666667a53.393333 53.393333 0 0 1 53.333333-53.333334h253.413334a52.986667 52.986667 0 0 1 37.713333 15.62l109.253333 109.253334a10.573333 10.573333 0 0 0 7.54 3.126666H842.666667a53.393333 53.393333 0 0 1 53.333333 53.333334v74.666666H173.773333a96.2 96.2 0 0 0-92.613333 70.74z m922-7.113333a52.933333 52.933333 0 0 0-42.386667-20.96H173.773333a53.453333 53.453333 0 0 0-51.453333 39.333333L11.773333 828.666667a53.333333 53.333333 0 0 0 51.453334 67.333333h787a53.453333 53.453333 0 0 0 51.453333-39.333333l110.546667-405.333334a52.953333 52.953333 0 0 0-9.073334-46.373333z"
                      fill="#17abe3"
                    />
                  </svg>
                  <el-image
                    v-else
                    fit="cover"
                    class="video-img"
                    :src="cg.ico"
                  />
                </div>
                <div class="column content">
                  <span class="video-name">{{ cg.name }}</span>
                  <span class="video-tag">{{ cg.name }}</span>
                </div>
              </div>
            </div>
            <div
              v-for="(video) in videoListTemp"
              :key="video.vid"
              style="margin:10px;height: 100%;"
              @click="onVideoClick(video.id,video.grouping)"
            >
              <div class="column video">
                <el-image
                  v-if="video.url.includes('mp3')"
                  fit="cover"
                  class="video-img"
                  :src="require('@/assets/mp3-logo.jpg')"
                />
                <el-image v-else class="video-img" fit="cover" :src="video.cover" />
                <div class="column content">
                  <span class="video-name">{{ video.name }}</span>
                  <span class="video-tag">{{ video.tag }}</span>
                </div>
              </div>
            </div>
            <el-pagination
              v-if="videoList.length>20"
              :page-size="20"
              layout="prev, pager, next"
              :total="videoList.length"
              style="position: fixed;bottom: 50px;"
              @current-change="videoListIndex"
            />
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <!-- 电子书 -->
    <div v-if="type ===2" class="book">
      <div class="row book-div">
        <el-image class="img" fit="cover" :src="bookInfo.surfacePlot" lazy />
        <div class="column start">
          <span class="cata-t">目录大纲</span>
          <span v-for="(cata) in bookCataList" :key="cata.id" class="cata">{{ cata.name }}</span>
        </div>
        <div class="column info">
          <span class="info-0">版权信息</span>
          <span class="info-1">书名：{{ bookInfo.name }}</span>
          <span>作者：{{ bookInfo.author }}</span>
          <span>时间：2022年1月8日</span>
          <span>字数：104115</span>
          <span>版权所有·侵权必究</span>
        </div>
        <div class="read" @click="onRead(1)">开始阅读</div>
      </div>
    </div>
    <!-- 电子书阅读 -->
    <div v-if="type ===3" class="row read">
      <div
        style="background: white;padding: 20px;border-radius:10px 0px 0px 10px ;height: calc(100% - 90px);width: 260px;"
      >
        <el-scrollbar class="scrollbar" style="background: #ECECEC;border-radius:8px; ">
          <div class="column cata">
            <div class="row" style=" display: flex;align-items: center;">
              <img src="../assets/images/目录.png" style="width:14px;height:14px;" />
              <span style="color: #5961CD; font-size: 14px;margin-left: 4px;">目录大纲</span>
            </div>
            <div
              style="width: 178px;height: 2px;background: #D0D0D0;border-radius: 1px 1px 1px 0px;margin-top: 10px;"
            ></div>
            <div
              v-for="(cata) in bookCataList"
              :key="cata.id"
              class="column"
              @click.stop="onRead(cata.cid)"
            >
              <span
                class="cata1"
                :style="{ color: cata.cid===selectCid?itemCackgroud:''}"
              >{{ cata.name }}</span>
              <div
                v-for="(cata2) in cata.list"
                :key="cata2.id"
                class="column"
                @click.stop="onRead(cata2.cid)"
              >
                <span
                  class="cata2"
                  :style="{ color: cata2.cid===selectCid?itemCackgroud:''}"
                >{{ cata2.name }}</span>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <el-scrollbar
        class="scrollbar"
        style="width: calc(100% - 0px);height: calc(100% - 50px);background: white;border-radius:0px 10px 10px 0px ;"
      >
        <div class="body" v-html="bookBody"></div>
      </el-scrollbar>
    </div>
    <!-- 登录 -->
    <div v-if="isLogin" class="login-div">
      <div v-if="!isForgetPass" class="login column">
        <div v-if=" !isWxLogin ">
          <span style="font-size:20px;">账 号 登 录</span>
          <div class="row input">
            <span style="width: 100px;font-size:14px">账号：</span>
            <el-input
              v-model="account"
              size="medium"
              placeholder="请输入账号"
              suffix-icon="el-icon-user"
            />
          </div>
          <div class="row input">
            <span style="width: 100px;font-size:14px">密码：</span>
            <el-input
              v-model="pass"
              size="medium"
              placeholder="请输入密码"
              suffix-icon="el-icon-lock"
              show-password
            />
          </div>
          <div class="row input">
            <el-checkbox v-model="savePass" class="savePass">自动登录</el-checkbox>
            <el-link type="primary" class="forgetPass" @click="onForgetPass">忘记密码</el-link>
          </div>
          <el-button type="primary" round class="login-but" size="medium" @click="onLogin()">登 录</el-button>
        </div>
        <div v-else>
          <wxlogin
            :appid="wxlogin.appid"
            :scope="wxlogin.scope"
            :redirect_uri="wxlogin.redirect_uri"
          />
        </div>
        <div class="row thirdparty">
          <div v-if=" !isWxLogin " class="column weChat" @click="onWeChatLogin">
            <svg
              t="1634891221232"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1792"
              width="40"
              height="40"
            >
              <path
                d="M347.729118 353.0242c-16.487119 0-29.776737 13.389539-29.776737 29.776737S331.241998 412.677596 347.729118 412.677596s29.776737-13.389539 29.776737-29.776737-13.289617-29.876659-29.776737-29.876659zM577.749415 511.800156c-13.689305 0-24.880562 11.091335-24.880563 24.880562 0 13.689305 11.091335 24.880562 24.880563 24.880562 13.689305 0 24.880562-11.191257 24.880562-24.880562s-11.191257-24.880562-24.880562-24.880562zM500.909446 412.677596c16.487119 0 29.776737-13.389539 29.776737-29.776737s-13.389539-29.776737-29.776737-29.776737c-16.487119 0-29.776737 13.389539-29.776737 29.776737s13.289617 29.776737 29.776737 29.776737zM698.455113 511.600312c-13.689305 0-24.880562 11.091335-24.880562 24.880562 0 13.689305 11.091335 24.880562 24.880562 24.880562 13.689305 0 24.880562-11.091335 24.880562-24.880562-0.099922-13.689305-11.191257-24.880562-24.880562-24.880562z"
                fill="#00C800"
                p-id="1793"
              />
              <path
                d="M511.601093 0.799375C229.12178 0.799375 0.000781 229.820453 0.000781 512.399688s229.021077 511.600312 511.600312 511.600312 511.600312-229.021077 511.600312-511.600312S794.180328 0.799375 511.601093 0.799375z m-90.229508 634.504294c-27.37861 0-49.361436-5.595628-76.839969-10.991413l-76.640125 38.469945 21.882904-65.948477c-54.957065-38.370023-87.73146-87.831382-87.73146-148.084309 0-104.318501 98.722873-186.554254 219.32865-186.554255 107.815769 0 202.34192 65.648712 221.327088 153.979703-6.994536-0.799375-13.989071-1.298985-21.083529-1.298985-104.118657 0-186.454333 77.739266-186.454332 173.564403 0 15.98751 2.498048 31.275566 6.794692 45.964091-6.794692 0.599532-13.689305 0.899297-20.583919 0.899297z m323.547228 76.839969l16.48712 54.757221-60.153006-32.874317c-21.882904 5.495706-43.965652 10.991413-65.848555 10.991413-104.318501 0-186.554254-71.344262-186.554255-159.175644 0-87.631538 82.135831-159.175644 186.554255-159.175644 98.523029 0 186.254489 71.444184 186.254488 159.175644 0.099922 49.461358-32.774395 93.227166-76.740047 126.301327z"
                fill="#00C800"
                p-id="1794"
              />
            </svg>
            <span>微信登录</span>
          </div>
          <div v-else class="column weChat" @click="onAccountLogin">
            <svg
              t="1642740372153"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9720"
              width="40"
              height="40"
            >
              <path
                d="M512 288m-224 0a224 224 0 1 0 448 0 224 224 0 1 0-448 0Z"
                fill="#d81e06"
                p-id="9721"
              />
              <path d="M448 320h128a64 64 0 1 1-128 0z" fill="#d81e06" p-id="9722" />
              <path
                d="M96 576m192 0l448 0q192 0 192 192l0 0q0 192-192 192l-448 0q-192 0-192-192l0 0q0-192 192-192Z"
                fill="#d81e06"
                p-id="9723"
              />
            </svg>
            <span>账号登录</span>
          </div>
        </div>
      </div>
      <div v-else class="login column">
        <span style="font-size:20px;">忘 记 密 码</span>
        <div class="row input">
          <span style="width: 100px;font-size:14px">手机号</span>
          <el-input
            v-model="account"
            size="medium"
            placeholder="请输入手机号"
            suffix-icon="el-icon-user"
          />
        </div>
        <div class="row input">
          <span style="width: 100px;font-size:14px">新密码：</span>
          <el-input
            v-model="pass"
            size="medium"
            placeholder="请输入新密码"
            suffix-icon="el-icon-lock"
            show-password
          />
        </div>
        <div class="row input">
          <span style="width: 100px;font-size:14px">图形验证码</span>
          <el-input
            v-model="imgCode"
            size="medium"
            placeholder="右侧图形验证码"
            suffix-icon="el-icon-eleme"
            style="width:194px"
          />
          <el-image fit="cover" :src="imgCodeUrl" lazy @click="onRefreshImg" />
        </div>
        <div class="row input">
          <span style="width: 100px;font-size:14px">短信验证码</span>
          <el-input
            v-model="smsCode"
            size="medium"
            placeholder="手机短信验证码"
            suffix-icon="el-icon-chat-line-square"
            style="width:194px"
          />
          <el-button
            type="primary"
            class="login-sms"
            size="medium"
            style="width:90px"
            @click="onSendSms()"
          >发送</el-button>
        </div>
        <el-button type="primary" round class="login-but" size="medium" @click="onModif()">确 认 修 改</el-button>
        <i class="el-icon-back back" @click="isForgetPass = !isForgetPass"></i>
      </div>
    </div>
    <div v-if="isSet" class="set-div"></div>
    <div v-if="userInfo.serviceQrCode!==null" class="app-fixed column" @click="onService">
      <svg
        t="1648109769160"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1281"
        data-darkreader-inline-fill
        width="20"
        height="20"
      >
        <path
          d="M608.929951 671.715102c-34.230808 0-60.892995-2.580212-68.461616-3.440282-17.545439-2.064169-30.274483-17.717453-28.210314-35.262893 1.892155-17.545439 17.545439-30.102469 35.262893-28.210314 79.642533 8.77272 201.772552 1.204099 223.102301-43.175542 7.568621-15.825298 26.662187-22.705863 42.659499-14.965228 15.997312 7.568621 22.705863 26.834201 14.965228 42.659499C794.361162 659.330086 683.412061 671.715102 608.929951 671.715102z"
          p-id="1282"
          fill="white"
        />
        <path
          d="M896.021502 639.892491c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-110.261045-42.831514-15.309256c-12.729044-4.472367-21.32975-16.685369-21.32975-30.102469 0-158.769024-129.182597-287.951621-287.951621-287.951621-158.769024 0-287.951621 129.182597-287.951621 287.951621 0 10.320847-4.988409 19.953637-13.417101 25.974131l-18.577524 13.245087 0 116.281539c0 17.717453-14.277171 31.994625-31.994625 31.994625s-31.994625-14.277171-31.994625-31.994625l0-132.794893c0-10.320847 4.988409-19.953637 13.417101-25.974131l18.921552-13.589115c8.77272-186.291282 163.069377-335.255501 351.596842-335.255501C698.377289 100.284226 851.469847 245.980178 863.338821 429.519234l43.51957 15.48127c12.729044 4.472367 21.32975 16.685369 21.32975 30.102469l0 132.794893C928.016126 625.61532 913.738955 639.892491 896.021502 639.892491z"
          p-id="1283"
          fill="white"
        />
        <path
          d="M639.892491 783.696288 639.892491 774.063497c28.0383-21.845792 50.400134-43.003528 63.645221-72.761969-29.070385 4.644381-61.581052 2.92424-87.727196 2.92424-31.994625 0-59.344868 0.860071-76.030237-1.032085-35.090879-3.956325-60.376953-38.703175-56.592642-73.794053 3.956325-35.090879 35.778935-60.720981 70.697799-56.592642 71.213842 7.568621 149.82429-0.688056 181.302873-13.933143 1.376113-29.242399 1.032085-60.032925 1.032085-83.426844 0-136.923232-83.254829-218.113892-216.737779-221.038132-0.688056 0-18.749538 0-19.437594 0-133.48295 2.752226-213.469511 84.974971-213.469511 222.070217 0 84.630942 1.376113 222.758273 97.359987 297.756425l0 9.63279c-127.978498 19.953637-226.026541 72.589955-226.026541 142.083655 0 17.717453 14.793214 34.230808 32.510667 34.230808l628.023518 0c17.717453 0 39.907274-16.513355 39.907274-34.230808C858.522426 856.286242 768.043004 803.821939 639.892491 783.696288z"
          p-id="1284"
          fill="white"
        />
      </svg>
      <!-- <el-image class="image" :src="require('@/assets/logo.png')" fit="fit" /> -->
      <span style="color: #F6C0C0;font-size: 12px;">客服</span>
      <!-- <span>敬请期待</span> -->
    </div>
    <div class="bott">
      <span>英语习得教育 © 2021 版权所有</span>
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51015602000228"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      >
        <img
          src="../icons/record.png"
          style=" float: left; width: 17px;height: 17px;margin-right: 3px;"
        />
        川公网安备 ICP备2021011205号-3
      </a>
    </div>
    <!-- 客服二维码 -->
    <div
      v-if="!serviceQrCode && !isLogin && userInfo.serviceQrCode!==null"
      class="service-QrCode"
      @click.stop="onServiceQrCode"
    >
      <div class="column qrCode-view">
        <el-image class="qrCode-img" fit="cover" :src="userInfo.serviceQrCode" lazy />
        <span class="qrCode-text">{{ userInfo.serviceQrCodeText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { getDictionariesList, smsCode } from '@/apis/sys' // weChatLogin
import { getVideoListNot, usTvResource } from '@/apis/video'
import { bookInfo, bookCataList, bookBody } from '@/apis/book'
import wxlogin from 'vue-wxlogin'
import { uuid } from 'vue-uuid'
import router from '@/router'
// 屏蔽NavigationDuplicated: Avoided redundant navigation to current location: "/index".
// import VueRouter from 'vue-router'
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
export default {
  name: 'Index',
  components: { wxlogin },
  data() {
    return {
      menuWidth: true, // 菜单宽度
      dir: '', // 视频目录
      imgCodeUrl: '', // 图形验证码地址
      imgUuid: '', // 图形验证码uuid
      imgCode: '', // 图形验证码Code
      smsCode: '', // 短信验证码
      homeHeight: '100%', // 高度
      grouping: [], // 分组
      childGroup: [], // 子分组
      gid: '', // 选择的分组
      pgid: [], // 选择的子分组上级集合
      page: {
        index: 1,
        rows: 100000
      },
      videoList: [], // 视频数组
      videoListTemp: [], // 视频分页
      isLogin: false, // 是否打开登录窗
      account: '', // 账号
      pass: '', // 密码
      savePass: true, // 记住密码
      isSet: false, // 是否打开设置窗口
      type: 1, // 加载类型
      bookInfo: {}, // 电子书信息
      bookCataList: [], // 目录
      bookBody: '', // 正文
      selectCid: '1', // 选择的正文ID
      itemCackgroud: '#c61200', // 选择的项颜色
      isWxLogin: false, // 是否微信登录
      wxlogin: {
        appid: 'wx7ab9e59225d15d4a',
        scope: 'snsapi_login',
        redirect_uri: 'https://huilianyingyu.com?isbinding=False'
      }, // 微信登录信息
      isForgetPass: false, // 忘记密码
      usTVList: [], // 学习资源（美剧）
      userInfo: {}, // 用户数据
      serviceQrCode: false, // 未点击客服二维码弹窗
      scenarios: [
        {
          name: '1.初级',
          type: 1,
          list: [
            {
              name: '基础1',
              type: 2
            }
          ]
        },
        {
          name: '2.中级',
          type: 1,
          list: [
            {
              name: '低级1',
              type: 2
            }
          ]
        },
        {
          name: '3.高级',
          type: 1,
          list: [
            {
              name: '中级1',
              type: 2
            }
          ]
        }
      ], // 语块多情景文件夹集合
      scenariosList: [], // 多情景语块
      scenariosType: 0, // 多情景语块 当前文件夹深度
      scenariosName: '', // 多情景语块 当前文件夹名称
      scenariosName2: '' // 多情景语块 当前选择的二级名称
    }
  },
  mounted() {
    var that = this
    this.dir = this.$route.query.dir
    Vue.$openLocalFile(true)
    // 窗口变化
    window.onresize = function() {
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        that.homeHeight = document.documentElement.clientHeight - 80 + 'px'
      }
    }
  },
  created() {
    var that = this
    that.imgUuid = uuid.v1()
    that.imgCodeUrl =
      Vue.$conf.service[Vue.$conf.environment] +
      'Sys/ImgCode?guid=' +
      that.imgUuid
    Vue.$type = function(type) {
      that.type = type
      if (type === 2) {
        bookInfo().then(res => {
          that.bookInfo = res.data
        })
        bookCataList().then(res => {
          that.bookCataList = res.data
          console.log(res)
        })
      }
    }
    Vue.$menuShow = function(show) {
      that.menuWidth = show
    }
    var userInfo = JSON.parse(localStorage.getItem('user'))
    if (localStorage.getItem('savePass') !== null) {
      this.savePass = localStorage.getItem('savePass')
    }
    if (userInfo === null) {
      this.$router.push('/login')
      // this.isLogin = true
      // document.body.style.overflow = 'hidden'
    } else {
      that.userInfo = userInfo
      if (localStorage.getItem('serviceQrCode')) {
        this.serviceQrCode = localStorage.getItem('serviceQrCode')
      }
    }
    var jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction
    if (!jurisdiction.menus['ov']) {
      router.push({
        name: 'Noaccess'
      })
      return
    }
    getDictionariesList({
      grouping: '视频分组',
      names: jurisdiction.videos
    }).then(res => {
      this.grouping = res.data
      if (this.dir) {
        var dirs = this.dir.split('-')
        this.gid = dirs[0]

        this.pgid = []
        this.pgid.push(this.gid)
        this.getGrouping(this.gid)
        if (dirs.length >= 3) {
          this.onScenarios(1, dirs[1])
          this.onScenarios(2, `${dirs[1]}-${dirs[2]}`)
        }
      } else {
        this.gid = this.grouping[0].id
        this.getGrouping(this.gid)

        this.pgid = []
        this.pgid.push(this.gid)
        this.getGrouping(this.gid)
      }
      this.onGetVideoList()
    })

    // 其它页面进入 是否需要切换类型
    var pagetype = this.$route.params.type
    if (pagetype) {
      Vue.$type(pagetype)
    }

    this.scenariosList = this.scenarios
  },
  methods: {
    // tabs选择事件
    onHandleClick() {
      if (this.gid === '-1') {
        usTvResource().then(res => {
          this.usTVList = res.data
        })
      } else {
        this.page = {
          index: 1,
          rows: 100000
        }
        this.onGetVideoList()
      }
      this.pgid = []
      this.pgid.push(this.gid)
      this.getGrouping(this.gid)
    },
    // 加载视频列表
    onGetVideoList() {
      // 语块多情景不加载
      if (this.gid !== '8') {
        getVideoListNot({
          gid: this.gid,
          page: this.page
        }).then(res => {
          this.videoList = res.data
          this.videoListIndex(1)
        })
      } else {
        if (this.scenariosName2 && this.scenariosType === 2) {
          getVideoListNot({
            gid: this.gid,
            tag: this.scenariosName2,
            page: this.page
          }).then(res => {
            this.scenariosList = res.data
          })
        }
      }
    },
    // 视频点击事件
    onVideoClick(id, grouping) {
      this.$router.push({
        path: '/player',
        query: {
          g: grouping,
          v: id,
          t: this.scenariosName2,
          dir: `${this.gid}-${this.scenariosName2}`
        }
      })
    },
    // 开始阅读
    onRead(cid) {
      this.selectCid = cid
      this.type = 3
      this.homeHeight = document.documentElement.clientHeight - 80 + 'px'
      bookBody({ cid: cid }).then(res => {
        this.bookBody = res.data
      })
    },
    // 忘记密码切换
    onForgetPass() {
      this.pass = ''
      this.smsCode = ''
      this.isForgetPass = !this.isForgetPass
    },
    // 刷新验证码
    onRefreshImg() {
      this.imgUuid = uuid.v1()
      this.imgCodeUrl =
        Vue.$conf.service[Vue.$conf.environment] +
        'Sys/ImgCode?guid=' +
        this.imgUuid
    },
    // 发送验证码
    onSendSms() {
      var that = this
      smsCode({
        guid: this.imgUuid,
        imgCode: this.imgCode,
        tele: this.account
      }).then(res => {
        that.$notify({
          title: '成功',
          message: '验证码发送成功',
          type: 'success'
        })
      })
    },
    // 学习资料
    onUsTvClick() {
      if (this.userInfo.grade !== 1.1) Vue.$OnOepnLocalFile()
    },
    // 客服二维码
    onServiceQrCode() {
      this.serviceQrCode = true
      localStorage.setItem('serviceQrCode', true)
    },
    // 打开客服
    onService() {
      this.serviceQrCode = false
    },
    // 语块多情景点击
    onScenarios(type, name) {
      switch (type) {
        case 0:
          if (this.scenariosType === 0) {
            return
          } else {
            if (this.scenariosType === 1) {
              this.scenariosList = this.scenarios
            } else if (this.scenariosType === 2) {
              // this.scenariosList = this.scenarios.filter(
              //   (item, index) => item.name === this.scenariosName
              // )[0].list
              this.scenariosList = []
              switch (this.scenariosName) {
                case '1.初级':
                  for (let index = 1; index <= 94; index++) {
                    this.scenariosList.push({
                      type: 2,
                      name: `1.初级-${index < 10 ? `0${index}` : index}`
                    })
                  }
                  break
                case '2.中级':
                  for (let index = 1; index <= 53; index++) {
                    this.scenariosList.push({
                      type: 2,
                      name: `2.中级-${index < 10 ? `0${index}` : index}`
                    })
                  }
                  break
                case '3.高级':
                  for (let index = 1; index <= 53; index++) {
                    this.scenariosList.push({
                      type: 2,
                      name: `3.高级-${index < 10 ? `0${index}` : index}`
                    })
                  }
                  break
              }
              type = 1
            }
          }
          break
        case 1:
          this.scenariosList = []
          switch (name) {
            case '1.初级':
              for (let index = 1; index <= 94; index++) {
                this.scenariosList.push({ type: 2, name: `1.初级-${index < 10 ? `0${index}` : index}` })
              }
              break
            case '2.中级':
              for (let index = 1; index <= 53; index++) {
                this.scenariosList.push({ type: 2, name: `2.中级-${index < 10 ? `0${index}` : index}` })
              }
              break
            case '3.高级':
              for (let index = 1; index <= 53; index++) {
                this.scenariosList.push({ type: 2, name: `3.高级-${index < 10 ? `0${index}` : index}` })
              }
              break
          }
          // this.scenariosList = this.scenarios.filter(
          //   (item, index) => item.name === name
          // )[0].list
          this.scenariosName = name
          this.dir = `${this.gid}-${this.scenariosName}`
          break
        case 2:
          this.scenariosName2 = name
          getVideoListNot({
            gid: this.gid,
            tag: name,
            page: this.page
          }).then(res => {
            this.scenariosList = res.data
          })
          this.dir = `${this.gid}-${this.scenariosName2}`
          break
      }
      this.scenariosType = type
    },
    // 下一级
    onGrouping(gid) {
      this.childGroup = []
      this.pgid.push(gid)
      // 加载子分组
      this.getGrouping(gid)
      // 加载视频
      getVideoListNot({
        gid: gid,
        page: this.page
      }).then(res => {
        this.videoList = res.data
        this.videoListIndex(1)
        // console.log(this.videoList.length)
      })
    },
    // 上一级
    onLastGrouping() {
      this.videoList = []
      var pid = this.pgid[this.pgid.length - 2]
      // 加载子分组
      this.getGrouping(pid)
      // 加载视频
      getVideoListNot({
        gid: pid,
        page: this.page
      }).then(res => {
        this.videoList = res.data
        this.videoListIndex(1)
      })
      // 删除最后一个分组数据
      this.pgid = this.pgid.slice(0, -1)
    },
    // 加载视频分组子文件夹(子分组)
    getGrouping(pid) {
      getDictionariesList({
        grouping: '视频分组',
        pid: pid
      }).then(res => {
        this.childGroup = res.data
      })
    },
    // 分页
    videoListIndex(e) {
      this.videoListTemp = this.videoList.slice((e - 1) * 20, e * 20)
    }
  }
}
</script>

<style lang="less" scoped>

//滚动条
.scrollbar {
  // height: 100%;
  margin-bottom: 50px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
// 禁止复制
* {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.video-list {
  margin: 0px 20px 40px 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab1 {
  width: calc(100% - 245px) !important;
  height: calc(100% - 30px) !important;
}
.tab2 {
  width: calc(100% - 45px) !important;
  height: calc(100% - 30px) !important;
}
.tab-name {
  font-size: 18px;
}
.content {
  // position: absolute;
  // bottom: 0px;
  width: 100%;
  // background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
  // color: #333333;
  .video-name {
    // position: absolute;
    // left: 0px;
    // bottom: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
  .video-tag {
    // position: absolute;
    // left: 0px;
    // bottom: 20px;
    font-size: 12px;
    color: #999999;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
.us-tv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  .img {
    border-radius: 10px;
    width: 200px;
    height: 300px;
  }
  .name {
    margin-top: 5px;
    font-size: 12px;
    color: #535353;
  }
}
.login-div {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(97, 97, 97, 0.6);
  z-index: 10;
  overflow: hidden;
}
.login {
  width: 500px;
  // height: 340px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  .back {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}
.hl {
  font-size: 36px;
  color: white;
  font-weight: bold;
}
.hl-login {
  font-size: 18px;
  color: #e7e7e7;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
}
.account {
  margin-top: 60px !important;
}
.input {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.savePass {
  position: absolute;
  left: 0px;
}
.forgetPass {
  position: absolute;
  right: 0px;
}
.login-but {
  margin-top: 30px;
  width: 300px;
}
.float {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.set-div {
  width: 500px;
  height: 500px;
}
.login-hl {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c61200;
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 100% 100%;
  position: absolute;
  top: -100px;
}
.bott {
  position: fixed;
  height: 50px;
  width: 100%;
  background: #b62b1a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  bottom: 0px;
  left: 0px;
  font-size: 12px;
  z-index: 100000;
}
.bott a {
  color: white;
  margin-left: 10px;
}
.app-fixed {
  position: fixed;
  bottom: 80px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c61200;
  border-radius: 10px 0px 0px 10px;
  .image {
    border-radius: 10px 0px 0px 10px;
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 12px;
    color: white;
    padding: 2px;
  }
}
.thirdparty {
  margin-top: 10px;
}
.weChat {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weChat span {
  font-size: 12px;
  color: #525252;
  margin-top: 5px;
}
// 封面
.book {
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // background: #343434;
  border-radius: 3px;
  cursor: pointer;
  .book-div {
    position: relative;
  }
  .img {
    height: 300px;
    width: 220px;
  }
  .info {
    // margin-top: -160px;
    margin-left: 20px;
    .info-0 {
      margin-top: 0px;
      font-size: 20px;
      color: black;
    }
    .info-1 {
      font-size: 20px;
      color: rgb(44, 44, 44);
    }
    font-size: 14px;
    color: rgb(88, 88, 88);
    // font-weight: bold;
    span {
      margin-top: 10px;
    }
  }
  .start {
    // margin-top: -100px;
    margin-left: 40px;
    // height: 500px;
    width: 200px;
    // background: #bc3a1e;
    .cata-t {
      font-size: 20px;
      color: black;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .cata {
      margin-top: 5px;
      font-size: 12px;
      color: rgb(82, 82, 82);
    }
  }
  .read {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 20px;
    width: 200px;
    height: 50px;
    background: #ac1114;
    border-radius: 5px;
    color: white;
  }
}
// 电子书阅读
.read {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  .cata {
    padding: 10px;
    // background: rgb(238, 238, 238);
    // width: 300px;
  }
  .cata1 {
    cursor: pointer;
    padding: 4px;
    font-size: 14px;
    font-weight: bold;
  }
  .cata2 {
    cursor: pointer;
    padding: 4px;
    font-size: 12px;
    margin-left: 10px;
  }
  .body {
    background: white;
    padding: 20px;
    // width: 100%;
    height: 100%;
    margin-bottom: 50px;
  }
}
.service-QrCode {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.493);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrCode-view {
    width: 320px;
    background: white;
    padding: 0px s0px 20px 0px;
    border-radius: 20px;
    // height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qrCode-img {
    border-radius: 20px;
  }
  .qrCode-text {
    font-size: 12px !important;
    // font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
.tab-name {
  font-size: 14px !important;
}
.bc1{
  font-weight: bold;
}
</style>

<style>
.el-tabs__content{
  height: calc(100%  -  10px) !important;
}

.el-tabs__item {
  color: #999999 !important;
}
.el-tabs__item.is-active {
  color: #c61200 !important;
  z-index: 1;
}
.el-tabs__active-bar {
  height: 40px;
  border-radius: 5px 5px 0px 0px;
  /* padding: 10px;
  margin-left: -10px; */
}
.el-tabs__content {
  overflow: hidden;
  position: relative;
  height: calc(100% - 95px);
}
.el-pager li {
  background: transparent;
}
.el-pagination button:disabled {
  background: transparent;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
}
.el-pagination .btn-next,
.el-pagination .btn-prevZ {
  background: transparent;
}
.el-tabs__item.is-active {
    color: #c61200 !important;
    z-index: 1;
    background: #c6120021 !important;
}
.bc1{
  color: #0041fc;
}
.bc2{
  color: #f800fc;
}
</style>
